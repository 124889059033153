@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-125%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-125%);
  }
}

.locale-button {
  background-color: #fff;
  border: 1px solid rgba(102, 102, 102, 0.19);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  align-items: center;
  padding: 8px 8px;
  cursor: pointer;
  text-transform: uppercase;
  font-family: "Lato", sans-serif;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.1rem;
}

.locale-button-txt {
  text-indent: -0.1rem;
  direction: rtl;
  font-family: "FuturaLT-bold", sans-serif;
  font-weight: 600;
  line-height: 15px;
}

.language-selector-modal {
  display: none;
  position: fixed;
  z-index: 20;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.4);
}

.language-selector-modal-open {
  display: block;
  animation: fadeIn 0.3s forwards;
}

.language-selector-modal-closing {
  animation: fadeOut 0.3s forwards;
}

.language-selector-modal-closing.language-selector-modal-content {
  animation: slideOut cubic-bezier(0.39, 0.49, 0.48, 1.35) 0.6s;
  animation-fill-mode: forwards;
}

.language-selector-modal-content {
  background-color: #ffffff;
  margin: 15% auto;
  padding: 16px;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  width: 300px;
  animation: slideIn cubic-bezier(0.39, 0.49, 0.48, 1.35) 0.6s;
  animation-fill-mode: forwards;

  @media (min-width: 992px) {
    position: fixed;
    right: 0;
    margin: 7% 3%;
  }
}

.language-selector-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.language-selector-modal-title {
  font-size: 20px;
  font-weight: 700;
  color: #333333;
  letter-spacing: -0.2px;
  margin-bottom: 24px;

  text-align: center;
}

.language-selector-inner-globe-icon {
  width: 100%;
  margin-left: 20px;
}

.language-selector-close:hover,
.language-selector-close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.language-selector-modal-list {
  padding: 0;
  margin: 0;
}

.language-selector-locale-item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 16px 0;
  border: 1px solid #ededed;
  border-radius: 8px;
  cursor: pointer;
  margin: 8px 0;
}

.language-selector-locale-name {
  text-transform: uppercase;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #333333;
  line-height: 1rem;
  letter-spacing: 0.1rem;
}

.language-selector-locale-divider {
  width: 0;
  height: 10px;
  border: 1px solid #ededed;
}

.language-selector-locale-language {
  font-family: sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 1rem;
}

@media (min-width: 992px) {
  .locale-button[data-is-horizontal="true"] {
    flex-direction: row;
  }
}

@media (max-width: 992px) {
  @keyframes slideIn {
    from {
      transform: translateY(-125%);
    }
    to {
      transform: translateY(20%);
    }
  }

  /* .locale-button {
    transform: rotate(90deg);
    margin-left: 0;
  } */

  /* .language-selector-globe-icon {
    transform: rotate(-90deg);
  } */

  /* .locale-button-txt {
    transform: rotate(-90deg);
  } */

  /* .language-selector-modal-title {
    text-align: center;
  }

  .language-selector-inner-globe-icon {
    width: 100%;
    margin-left: 20px;
  } */
}
